import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [name, setName] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/chat', { message: name });

      setResponse(res.data.reply);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      setResponse('Ошибка при отправке запроса');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Введите своё имя</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Ваше имя"
        />
        <button type="submit">Отправить</button>
      </form>
      {response && <h2>{response}</h2>}
    </div>
  );
}

export default App;
